import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import componentHelper from './helpers/componentHelper';
import SalesCRMRoster from './constants/json/SalesCRMRoster.json';
import SalesCRMInsideSalesAgentsRoster from './constants/json/SalesCRMInsideSalesAgentsRoster.json';
import SupplyCRMRoster from './constants/json/SupplyCRMRoster.json';
import SalesCRMCityOpsManagerRoster from './constants/json/SalesCRMCityOpsManagerRoster.json'
import SalesCRMDeliveryManagerRoster from './constants/json/SalesCRMDeliveryManagerRoster.json'
import HubAdminAgentsRoster from './constants/json/HubAdminAgentsRoster.json';
import webInboundAgentsRoster from './constants/json/webInboundAgentsRoster.json';
import InsideSalesAdminAgentsRoster from './constants/json/InsideSalesAdminAgentsRoster.json';
import SalesCRMRosterHubInspector from './constants/json/SalesCRMRosterHubInspector.json';
import DeliveryConsultantsRoster from './constants/json/DeliveryConsultantsRoster.json';

import './base.scss';
import Login from './temp/Login';
const getJsonFile = (component) => {
  switch (component) {
    case 'supplyCRMRoster':
      return SupplyCRMRoster;
    case 'salesCRMRoster':
      return SalesCRMRoster;
    case 'salesCRMInsideSalesAgentRoster':
      return SalesCRMInsideSalesAgentsRoster;
    case 'salesCRMCityOpsManagerRoster':
      return SalesCRMCityOpsManagerRoster;
    case 'webInboundAgentsRoster':
      return webInboundAgentsRoster;
    case 'SalesCRMDeliveryManagerRoster':
      return SalesCRMDeliveryManagerRoster;
    case 'hubAdminAgentsRoster':
      return HubAdminAgentsRoster;
    case 'insideSalesAdminAgentsRoster':
      return InsideSalesAdminAgentsRoster;
    case 'salesCRMRosterHubInspector':
      return SalesCRMRosterHubInspector;
    case 'deliveryConsultantsRoster':
      return DeliveryConsultantsRoster;
    default:
      return null;
  }
};

const App = () => {
  const [jsonStructure, updateJsonStructure] = useState({});

  const fetchComponent = (component) => {
    const jsonFile = getJsonFile(component);
    updateJsonStructure(jsonFile[component]);
  };

  useEffect(() => {
    const brokenPathname = window.location.pathname.split('/');
    fetchComponent(brokenPathname[2]);
  }, []);
  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/">
          {/* <Table /> */}
          {componentHelper({ component: jsonStructure })}
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
